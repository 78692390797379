/**
 * @deprecated
 * @module OnboardingStep
 * @desc
 * This file is deprecated and should not be used. It is kept for historical reasons and to prevent breaking changes. It should be removed in the future.
 */

/**
 *
 * ConfirmNewOrganisation
 *
 */
import * as React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { IOrganisation, organisationTypes } from 'types/types';

interface Props {
  organisationDetails: Partial<IOrganisation>;
  planDetails: {
    planHash?: string;
    plan_id?: number;
    name?: string;
    partner_name?: string;
  };
  onConfirm: () => void;
  onDismiss: () => void;
  saving?: boolean;
}

export function ConfirmNewOrganisation(props: Props) {
  const {
    onDismiss,
    onConfirm,
    organisationDetails,
    planDetails,
    saving = false,
  } = props;
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          backgroundColor: theme.palette.grey[200],
          borderRadius: 5,
          padding: 3,
        }}
      >
        <Stack spacing={5}>
          <Box>
            <Stack spacing={3}>
              <Box>
                <Typography variant={'h4'}>Organisation Details</Typography>
              </Box>

              <Stack direction={'row'}>
                <Typography variant="body2" fontWeight={500}>
                  Name:
                </Typography>
                <Typography variant={'body1'}>
                  {organisationDetails.name}
                </Typography>
              </Stack>
              <Stack direction={'row'}>
                <Typography variant="body2" fontWeight={500}>
                  Number:
                </Typography>
                <Typography variant={'body1'}>
                  {organisationDetails.number}
                </Typography>
              </Stack>
              <Stack direction={'row'}>
                <Typography variant="body2" fontWeight={500}>
                  Type:
                </Typography>

                <Typography variant={'body1'}>
                  {organisationTypes[organisationDetails.type]}
                </Typography>
              </Stack>

              <Stack direction={'row'}>
                <Typography variant="body2" fontWeight={500}>
                  Address:
                </Typography>

                <Typography variant={'body1'}>
                  {[
                    organisationDetails.premises,
                    organisationDetails.address_line_1,
                    organisationDetails.address_line_2,
                    organisationDetails.locality,
                    organisationDetails.region,
                    organisationDetails.country,
                    organisationDetails.postal_code,
                  ]
                    .filter(x => x !== undefined && x !== null && x !== '')
                    .map((line, ind, arr) => (
                      <React.Fragment key={`address-line-${ind}`}>
                        {line} {ind < arr.length - 1 ? <br /> : null}
                      </React.Fragment>
                    ))}
                </Typography>
              </Stack>
            </Stack>
          </Box>
          <Box>
            <Stack spacing={3}>
              <Box>
                <Typography variant={'h4'}>Plan Details</Typography>
              </Box>
              <Stack direction={'row'}>
                <Typography variant="body2" fontWeight={500}>
                  Name:
                </Typography>
                <Typography variant={'body1'}>{planDetails.name}</Typography>
              </Stack>
              <Stack direction={'row'}>
                <Typography variant="body2" fontWeight={500}>
                  Partner:
                </Typography>
                <Typography variant={'body1'}>
                  {planDetails.partner_name}
                </Typography>
              </Stack>
            </Stack>
          </Box>

          <Divider />

          <Stack direction={{xs:'column', sm: 'row'}} justifyContent={'flex-end'}>
            <Button
              variant={'text'}
              size={'small'}
              onClick={() => onDismiss()}
              disabled={saving}
            >
              Back: select plan
            </Button>
            <Button
              onClick={() => onConfirm()}
              disabled={saving}
              startIcon={saving ? <CircularProgress size={20} /> : null}
            >
              Confirm details and create organisation
            </Button>
          </Stack>
        </Stack>
      </Box>
    </>
  );
}
