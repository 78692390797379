import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  CardMedia,
  Grid,
  Link as ExternalLink,
  MenuItem,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  FormControl,
  InputLabel,
  Select,
  Paper,
} from '@mui/material';
import { FeatureCode, features, IOrganisation } from 'types/types';
import { DashboardBox } from 'app/components/DashboardBox';
import { Link, useParams } from 'react-router-dom';
import { DashboardCard } from 'app/components/DashboardCard';
import { useDispatch, useSelector } from 'react-redux';
import { selectAvailableOrganisations } from 'app/providers/OrganisationSelector/slice/selectors';
import { useRiskRegisterSectionSlice } from '../RiskRegisterSection/slice/hook';
import { useChecklistsSlice } from '../Checklists/slice/hook';
import { useMeetingsSlice } from '../Meetings/slice/hook';
import { selectMeetings } from '../Meetings/slice/selectors';
import { MeetingsList } from '../Meetings/MeetingsList';
import { selectRiskRegisterSection } from '../RiskRegisterSection/slice/selectors';
import { RiskMatrix } from '../RiskRegisterSection/RiskMatrix';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { useHasPermission } from 'app/providers/AuthProvider/useHasPermission';
import { CardBody } from 'app/components/CardBody';
import { selectAuthProvider } from 'app/providers/AuthProvider/slice/selectors';
import AcademyImage from './assets/academy-thumb.png';
import RiskRegisterImage from './assets/risk_register.png';
import BoardImage from './assets/board_image.png';
import ReferAFriend from './assets/refer_a_friend.jpg';
import RiskMatrixImage from './assets/risk_matrix.png';
import { ReactComponent as BoardIcon } from './assets/board_icon.svg';
import { ReactComponent as ActionIcon } from './assets/checklist_icon.svg';
import { usePartnerDetailsSlice } from '../PartnerDetails/slice/hook';
import { selectPartnerDetails } from '../PartnerDetails/slice/selectors';
import { organisationPageActions } from '../OrganisationPage/slice';
import { useConfirm } from 'utils/useConfirm';
import { noop } from 'utils/noop';
import { Helmet } from 'react-helmet-async';
import { StatusChip } from './StatusChip';
import { RolloverMenu } from './RolloverMenu';
import { TimerSubtitle } from './TimerSubtitle';
import { useReloadCurrentOrganisation } from '../OrganisationPage/useReloadCurrentOrganisation';
import { DashboardActionsWidget } from 'app/widgets/DashboardActionsWidget';
import { ActionRegister } from 'app/widgets/ActionRegister';
import { TitledField } from 'app/components/TitledField';

interface Props {
  organisation: IOrganisation;
}

export function Dashboard(props: Props) {
  const { actions: meetingsActions } = useMeetingsSlice();
  const { actions: riskRegisterSectionActions } = useRiskRegisterSectionSlice();
  const { actions: checklistsActions } = useChecklistsSlice();
  const { actions: partnerDetailsActions } = usePartnerDetailsSlice();
  const { reloadOrganisation } = useReloadCurrentOrganisation();
  const [initialised, setInitialised] = useState(false);

  const { organisation } = props;
  const dispatch = useDispatch();

  const { loadUser } = useSelector(selectAuthProvider);
  const { loadPartner } = useSelector(selectPartnerDetails);
  const { loadMeetings } = useSelector(selectMeetings);
  const availableOrganisations = useSelector(selectAvailableOrganisations);
  const { loadOrganisationRiskRegister } = useSelector(
    selectRiskRegisterSection,
  );

  const confirm = useConfirm();

  const { id } = useParams();

  const [selectedFeature, setSelectedFeature] = useState<string>('');

  useEffect(() => {
    setTimeout(() => {
      loadContent();
    }, 250);
  }, [organisation]);

  const disabledFeatures = useMemo(
    () =>
      Object.keys(organisation.features).filter(
        c => organisation.features[c].disabled,
      ),
    [organisation.features],
  );

  const loadContent = () => {
    if (organisation.risk_register_activated) {
      dispatch(
        riskRegisterSectionActions.loadOrganisationRiskRegisterRequest({
          organisation_id: organisation.id,
        }),
      );
    }
    if (organisation.checklists_activated) {
      dispatch(
        checklistsActions.loadOrganisationChecklistsRequest({
          organisation_id: organisation.id,
        }),
      );
    }
    if (organisation.board_activated) {
      dispatch(
        meetingsActions.loadMeetingsRequest({
          organisation_id: organisation.id,
        }),
      );
    }
    dispatch(
      partnerDetailsActions.loadPartnerRequest({
        organisation_id: organisation.id,
      }),
    );
  };

  const { hasPermission } = useHasPermission();

  const canViewActions = hasPermission(
    'view-action-register',
    'organisation',
    organisation.id,
  );
  const canManageActions = useMemo(() => {
    if (!loadUser.data) return false;
    return loadUser.data.access_organisations
      .find(org => org.id === organisation.id)
      .committees.reduce((acc, c) => {
        return (
          acc ||
          hasPermission('manage-action-register-items', 'committee', c.id)
        );
      }, false);
  }, [loadUser.data]);

  const canManageFeatures = hasPermission(
    'manage-features',
    'organisation',
    id,
  );

  const canViewChecklists = hasPermission(
    'view-checklists',
    'organisation',
    id,
  );

  const disableFeature = (featureCode: FeatureCode) => {
    confirm({
      title: `Turn off the ${features[featureCode]} feature?`,
      description: (
        <>
          Clicking 'OK' will hide the feature throughout the app. Your data will
          be retained, and the feature can be easily restored via the 'Restore
          features' field found at the {isLg ? 'right column' : 'bottom'} of the
          dashboard page.
        </>
      ),
    })
      .then(() =>
        dispatch(
          organisationPageActions.manageFeatureRequest({
            id: +organisation.id,
            code: featureCode,
            action: 'disable',
          }),
        ),
      )
      .catch(noop);
  };

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));

  const hasFeaturesInGrid =
    (!organisation.features.board.disabled &&
      organisation.features.board.status !== 'not-allowed') ||
    (!organisation.features['risk-register'].disabled &&
      organisation.features['risk-register'].status !== 'not-allowed');

  if (!loadUser.data) return <LoadingIndicator />;

  const additionalResourcesCol = (
    <Grid item xs={12} lg={3}>
      <Stack spacing={2} height="100%">
        <ExternalLink
          href="https://www.governance360.com/refer-a-friend"
          target="_blank"
          rel="noopener"
          sx={{
            textDecoration: 'none',
            '&:hover': {
              opacity: 0.8,
            },
          }}
        >
          <Stack spacing={0}>
            <CardMedia
              component={'img'}
              src={ReferAFriend}
              alt=""
              sx={{
                height: 'auto',
                width: '100%',
                objectFit: 'contain',
              }}
            />
            <DashboardBox title="Refer a friend" sx={{ color: 'common.black' }}>
              <Stack spacing={0}>
                <Typography>Invite friends, and get rewards!</Typography>
                <Typography>
                  <Typography
                    component={'span'}
                    sx={{ pr: 0.8 }}
                    color={'primary.main'}
                  >
                    Refer now
                  </Typography>
                  for exclusive offers
                </Typography>
              </Stack>
            </DashboardBox>
          </Stack>
        </ExternalLink>

        <DashboardBox
          title="Brought to you by"
          sx={{
            borderTop: 15,
            borderTopColor: 'common.midnight',
            height: '100%',
          }}
        >
          <LoadingIndicator showContent={!!loadPartner.data}>
            {!!loadPartner.data && (
              <>
                <Box
                  sx={{
                    py: 2,
                  }}
                >
                  {!!loadPartner.data.logo_image ? (
                    <CardMedia
                      component={'img'}
                      src={loadPartner.data.logo_image}
                      alt={loadPartner.data.name}
                      sx={{
                        height: 120,
                        width: '100%',
                        objectFit: 'contain',
                      }}
                    />
                  ) : (
                    <Typography variant={'h5'}>
                      {loadPartner.data.name}
                    </Typography>
                  )}
                </Box>
                <Stack spacing={1}>
                  <Typography>
                    Current plan: {organisation.plan_name}
                  </Typography>
                  <Box>
                    <Button
                      variant="text"
                      component={ExternalLink}
                      href={loadPartner.data.contact_details.website}
                      target="_blank"
                      rel="noreferrer"
                      sx={{
                        p: 0,

                        color: 'primary.main',
                        fontWeight: 600,
                      }}
                    >
                      Visit the {loadPartner.data.name} portal
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      component={Link}
                      variant="text"
                      to={'./partner-details'}
                      sx={{
                        p: 0,

                        lineHeight: 1.2,
                      }}
                    >
                      Contact your Development Manager
                    </Button>
                  </Box>
                </Stack>
              </>
            )}
          </LoadingIndicator>
        </DashboardBox>
        {!!disabledFeatures.length &&
          hasPermission('manage-features', 'organisation', organisation.id) && (
            <TitledField title="Restore features">
              <FormControl fullWidth>
                <InputLabel>Select feature</InputLabel>
                <Select
                  label="Select feature"
                  value={selectedFeature || ''}
                  onChange={event => {
                    const code = event.target.value;
                    setSelectedFeature(code);
                    confirm({
                      title: 'Restore feature',
                      description: `Would you like to restore the ${code} feature? You can turn off this feature at any time.`,
                      confirmationText: 'Yes',
                      cancellationText: 'No',
                    })
                      .then(() =>
                        dispatch(
                          organisationPageActions.manageFeatureRequest({
                            id: +organisation.id,
                            code: code as FeatureCode,
                            action: 'enable',
                          }),
                        ),
                      )
                      .catch(() => {
                        setSelectedFeature('');
                      });
                    setSelectedFeature('');
                  }}
                >
                  {disabledFeatures.map(code => (
                    <MenuItem key={code} value={code}>
                      Restore {features[code]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </TitledField>
          )}
      </Stack>
    </Grid>
  );

  return (
    <>
      <Helmet title={'Dashboard'}>
        <meta name="description" content="Organisation dashboard" />
      </Helmet>
      <Box sx={{ py: 2 }}>
        <Stack>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={9}>
                <Stack spacing={3} height={'100%'}>
                  {!organisation.features.board.disabled &&
                    organisation.features.board.status !== 'not-allowed' && (
                      <DashboardCard
                        sx={{ height: '100%' }}
                        icon={<BoardIcon />}
                        title={'Board Meetings'}
                        chip={
                          <Stack direction={'row'} alignItems={'center'}>
                            <StatusChip feature={organisation.features.board} />
                            {hasPermission(
                              'manage-features',
                              'organisation',
                              organisation.id,
                            ) && (
                              <RolloverMenu
                                options={[
                                  {
                                    onClick: () => disableFeature('board'),
                                    children: <>Turn off this feature</>,
                                  },
                                ]}
                              />
                            )}
                          </Stack>
                        }
                      >
                        <LoadingIndicator
                          showContent={
                            (!loadMeetings.loading && loadMeetings.touched) ||
                            !organisation.features.board.activated
                          }
                        >
                          {loadMeetings.data.length &&
                          organisation.board_activated ? (
                            <>
                              <MeetingsList
                                meetings={loadMeetings.data}
                                pastLimit={2}
                                futureLimit={2}
                              />
                              <Box>
                                <Button
                                  component={Link}
                                  to={'./meetings'}
                                  variant="text"
                                  sx={{ px: 0 }}
                                >
                                  Go to Board meetings page
                                </Button>
                              </Box>
                            </>
                          ) : (
                            <CardBody
                              title={
                                canManageFeatures
                                  ? 'Level up by creating your Board'
                                  : 'Attend board meetings securely'
                              }
                              subtitle={
                                canManageFeatures ? (
                                  <TimerSubtitle
                                    title={'5 minutes set up time for Board'}
                                  />
                                ) : undefined
                              }
                              image={BoardImage}
                              buttonText={
                                canManageFeatures
                                  ? organisation.features.board.activated
                                    ? 'Get started with boards'
                                    : 'Activate boards'
                                  : undefined
                              }
                              buttonProps={
                                organisation.features.board.activated
                                  ? { component: Link, to: './meetings' }
                                  : { component: Link, to: './activate/board' }
                              }
                              body={
                                <>
                                  <Box>
                                    <Typography
                                      sx={{
                                        '&::before': { content: '"•"', pr: 1 },
                                      }}
                                      variant="body2"
                                    >
                                      {canManageFeatures
                                        ? 'Effortlessly manage your meetings with secured document uploads for your meeting pack and seamless scheduling of committee meetings through preferred video-call software.'
                                        : 'Access your meeting materials securely. Seamlessly integrate meeting schedules with your calendar, and join video-call sessions with ease, enhancing your readiness and engagement.'}
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography
                                      sx={{
                                        '&::before': { content: '"•"', pr: 1 },
                                      }}
                                      variant="body2"
                                    >
                                      {canManageFeatures
                                        ? 'Proactively distribute meeting packs among board members, fostering alignment and ensuring a unified understanding.'
                                        : 'Receive meeting packs promptly, enabling you to align with fellow participants and grasp the agenda fully.'}
                                    </Typography>
                                  </Box>
                                </>
                              }
                            />
                          )}
                        </LoadingIndicator>
                      </DashboardCard>
                    )}

                  {!organisation.features.actions.disabled &&
                    !!hasPermission(
                      'view-dashboard-actions',
                      'organisation',
                      organisation.id,
                    ) && (
                      <DashboardActionsWidget
                        organisation={organisation}
                        chip={
                          <Stack direction={'row'} alignItems={'center'}>
                            <StatusChip feature={organisation.features.board} />
                            {hasPermission(
                              'manage-features',
                              'organisation',
                              organisation.id,
                            ) && (
                              <RolloverMenu
                                options={[
                                  {
                                    onClick: () => disableFeature('actions'),
                                    children: <>Turn off this feature</>,
                                  },
                                ]}
                              />
                            )}
                          </Stack>
                        }
                      />
                    )}

                  {!organisation.features['risk-register'].disabled &&
                    organisation.features['risk-register'].status !==
                      'not-allowed' && (
                      <DashboardCard
                        sx={{ height: '100%' }}
                        icon={
                          <CardMedia
                            component={'img'}
                            src={RiskRegisterImage}
                            alt=""
                          />
                        }
                        title={'Risk Register'}
                        chip={
                          <Stack direction={'row'} alignItems={'center'}>
                            <StatusChip
                              feature={organisation.features['risk-register']}
                            />
                            {hasPermission(
                              'manage-features',
                              'organisation',
                              organisation.id,
                            ) && (
                              <RolloverMenu
                                options={[
                                  {
                                    onClick: () =>
                                      disableFeature('risk-register'),
                                    children: <>Turn off this feature</>,
                                  },
                                ]}
                              />
                            )}
                          </Stack>
                        }
                      >
                        {organisation.risk_register_activated ? (
                          <>
                            <LoadingIndicator
                              showContent={!!loadOrganisationRiskRegister.data}
                            >
                              <RiskMatrix
                                riskRegister={loadOrganisationRiskRegister.data}
                                urlPrefix={'./risk-register/risk-matrix/'}
                              />
                            </LoadingIndicator>
                            <Box>
                              <Button
                                component={Link}
                                to={'./risk-register'}
                                variant="text"
                                sx={{ px: 0 }}
                              >
                                Browse your Risk Register in full
                              </Button>
                            </Box>
                          </>
                        ) : (
                          <CardBody
                            title={
                              canManageFeatures
                                ? 'Set up your Risk Register'
                                : "Review your organisation's Risk Register"
                            }
                            subtitle={
                              canManageFeatures ? (
                                <TimerSubtitle
                                  title={
                                    'Takes less than 4 minutes to start your Risk Register'
                                  }
                                />
                              ) : undefined
                            }
                            image={RiskMatrixImage}
                            buttonText={
                              canManageFeatures
                                ? 'Set up your Risk register now'
                                : undefined
                            }
                            buttonProps={{
                              component: Link,
                              to: './risk-register',
                            }}
                            body={
                              <>
                                <Box>
                                  <Typography
                                    sx={{
                                      '&::before': { content: '"•"', pr: 1 },
                                    }}
                                    variant="body2"
                                  >
                                    {canManageFeatures ? (
                                      <>
                                        Use our simple risk register tool to
                                        help you document, analyse and report
                                        your key organisational risks to all
                                        board members.
                                      </>
                                    ) : (
                                      <>
                                        Our simple risk register tool helps you
                                        and your Board document, analyse and
                                        report your key organisational risks
                                        once activated by your Board
                                        administrator.
                                      </>
                                    )}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    sx={{
                                      '&::before': { content: '"•"', pr: 1 },
                                    }}
                                    variant="body2"
                                  >
                                    {canManageFeatures ? (
                                      <>
                                        Accessible 24x7, move away from excel
                                        files and paper to a live, updatable
                                        register which only takes minutes to
                                        create and use.
                                      </>
                                    ) : (
                                      <>
                                        Accessible 24x7 (although only your
                                        Administrator can amend and update the
                                        data), move away from excel files and
                                        paper and view your risks in real-time
                                        here.
                                      </>
                                    )}
                                  </Typography>
                                </Box>
                              </>
                            }
                          />
                        )}
                      </DashboardCard>
                    )}
                </Stack>
              </Grid>

              {/* dashboard right column */}
              {/* add conditional here so that additional resources show full width features under md breakpoints */}
              {isLg && hasFeaturesInGrid && additionalResourcesCol}

              {!organisation.features['action-register'].disabled &&
                organisation.features['action-register'].status !==
                  'not-allowed' &&
                canViewActions && (
                  <Grid item xs={12}>
                    <DashboardCard
                      icon={<ActionIcon />}
                      title={'Action Register'}
                      chip={
                        <Stack direction={'row'} alignItems={'center'}>
                          <StatusChip
                            feature={organisation.features['action-register']}
                          />
                          {hasPermission(
                            'manage-features',
                            'organisation',
                            organisation.id,
                          ) && (
                            <RolloverMenu
                              options={[
                                {
                                  onClick: () =>
                                    disableFeature('action-register'),
                                  children: <>Turn off this feature</>,
                                },
                              ]}
                            />
                          )}
                        </Stack>
                      }
                    >
                      <Stack spacing={4}>
                        <ActionRegister
                          organisation_id={organisation.id}
                          viewMode={'dashboard'}
                          introText={
                            canManageActions ? (
                              <>
                                Manage and assign actions to other members. Use
                                the filters to easily organise actions, and
                                click on any action to update its details or
                                status. Additionally, you can create new actions
                                and manage all aspects of the task workflow.
                              </>
                            ) : (
                              <>
                                Here you can view all the actions across the
                                committees assigned to you and others. Use the
                                filters to find specific actions or view their
                                details.
                              </>
                            )
                          }
                        />
                      </Stack>
                    </DashboardCard>
                  </Grid>
                )}

              {!!loadUser.data.thinkific_enabled && (
                <Grid item xs={12}>
                  <Paper
                    square
                    variant="outlined"
                    sx={{ backgroundColor: 'common.beige', p: 4 }}
                  >
                    <Grid container>
                      <Grid item xs={12} sm={6} lg={7}>
                        <Stack
                          spacing={2}
                          justifyContent={'space-between'}
                          sx={{ height: '100%' }}
                        >
                          <Typography color={'common.midnight'}>
                            GOV360 ACADEMY
                          </Typography>
                          {isXs && (
                            <CardMedia
                              component={'img'}
                              src={AcademyImage}
                              sx={{
                                width: 480,
                                height: 480,
                              }}
                            />
                          )}
                          <Box>
                            <Stack spacing={2}>
                              <Typography variant="h2">
                                Build your skills with the Gov360 Academy
                              </Typography>
                              <Typography variant="body2">
                                As part of your plan you have access to learning
                                provided by the Gov360 Academy (topics will be
                                determined by your plan). Browse now for
                                information on how we can help you develop your
                                skills. (CPD accreditation subject to module)
                              </Typography>
                            </Stack>
                          </Box>

                          <Box>
                            <Button
                              fullWidth
                              sx={{ p: 2 }}
                              component={Link}
                              to={'/profile/academy-record'}
                            >
                              View your Academy record now
                            </Button>
                          </Box>
                        </Stack>
                      </Grid>
                      {!isXs && (
                        <Grid item xs={12} sm={6} lg={5}>
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                          >
                            <CardMedia
                              component={'img'}
                              src={AcademyImage}
                              sx={{
                                width: 280,
                                height: 280,
                              }}
                            />
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Paper>
                </Grid>
              )}

              {/*
              {canManageFeatures &&
                !organisation.features.checklists.disabled &&
                organisation.features.checklists.status !== 'not-allowed' && (
                  <DashboardCard
                    icon={<ChecklistIcon />}
                    title={'Compliance Checklists'}
                    chip={
                      <Stack direction={'row'} alignItems={'center'}>
                        <StatusChip
                          feature={organisation.features.checklists}
                        />
                        {hasPermission(
                          'manage-features',
                          'organisation',
                          organisation.id,
                        ) && (
                          <RolloverMenu
                            options={[
                              {
                                onClick: () => disableFeature('checklists'),
                                children: <>Turn off this feature</>,
                              },
                            ]}
                          />
                        )}
                      </Stack>
                    }
                  >
                    {organisation.checklists_activated ? (
                      <>
                        <LoadingIndicator
                          showContent={
                            loadOrganisationChecklists.data.filter(
                              cl => cl.status === 'active',
                            ).length > 0
                          }
                        >
                          <Box py={2}>
                            <DashboardChecklists
                              checklists={loadOrganisationChecklists.data.filter(
                                cl => cl.status === 'active',
                              )}
                            />
                          </Box>
                        </LoadingIndicator>
                        <Box>
                          <Button
                            component={Link}
                            to={'./checklists'}
                            variant="text"
                            sx={{  px: 0 }}
                          >
                            Review progress in Compliance Checklists
                          </Button>
                        </Box>
                      </>
                    ) : (
                      <CardBody
                        title={
                          canManageFeatures
                            ? 'Get started with Compliance Checklists'
                            : 'Review your Organisational Checklists'
                        }
                        subtitle={
                          canManageFeatures ? (
                            <TimerSubtitle
                              title={
                                'Most users take less than 5 minutes to finish their first checklist'
                              }
                            />
                          ) : undefined
                        }
                        buttonText={
                          canViewChecklists
                            ? 'Try out Compliance checklists now'
                            : undefined
                        }
                        buttonProps={{
                          component: Link,
                          to: './checklists',
                        }}
                        image={ChecklistsImage}
                        body={
                          <>
                            <Box>
                              <Typography
                                sx={{
                                  '&::before': { content: '"•"', pr: 1 },
                                }}
                                variant="body2"
                              >
                                {canManageFeatures ? (
                                  <>
                                    Our feature includes a series of simple
                                    checklists (subject to Plan) that help you
                                    document your key actions each month as a
                                    board administrator.
                                  </>
                                ) : (
                                  <>
                                    As part of Governance360, your Board
                                    administrator has access to a series of
                                    simple checklists (subject to Plan) that
                                    help document their key actions each month
                                    as a board administrator.
                                  </>
                                )}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                  '&::before': { content: '"•"', pr: 1 },
                                }}
                                variant="body2"
                              >
                                {canManageFeatures ? (
                                  <>
                                    Typically no more than 10 questions, keep on
                                    top of basic administration each month, save
                                    and/or export your results for all to see.
                                  </>
                                ) : (
                                  <>
                                    As a board member, you can review (but not
                                    edit) their outputs, helping you keep in
                                    touch with key information relevant to your
                                    role.
                                  </>
                                )}
                              </Typography>
                            </Box>
                          </>
                        }
                      />
                    )}
                  </DashboardCard>
                )} /*/}
            </Grid>
          </Box>
          {(!isLg || !hasFeaturesInGrid) && additionalResourcesCol}
        </Stack>
      </Box>
    </>
  );
}
