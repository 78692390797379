import React, { useCallback, useEffect, useMemo } from 'react';
import { ActionRegisterForm } from './ActionRegisterForm';
import { ActionRegisterTable } from './ActionRegisterTable';
import { useEffectOnChange } from '../../../utils/useEffectOnChange';
import { useDispatch, useSelector } from 'react-redux';
import { useActionRegisterSlice } from './slice/hook';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { RiAddCircleFill, RiDownload2Line } from 'react-icons/ri';
import { selectActionRegister } from './slice/selectors';
import useHandleApiResponse from '../../../utils/useHandleApiResponse';
import key from 'weak-key';
import { SelectControl } from 'app/components/SelectControl';
import { getApiUrl, serveFile } from '../../../utils/request';
import { now } from 'moment/moment';
import { noop } from 'lodash';
import { useHasPermission } from 'app/providers/AuthProvider/useHasPermission';

interface Props {
  introText?: React.ReactNode;
  organisation_id: number | string;
  committee_id?: number | string;
  meeting_id?: number | string;
  viewMode?: 'dashboard' | 'meeting' | 'default';
}

export function ActionRegister(props: Props) {
  const {
    introText,
    organisation_id,
    committee_id,
    meeting_id,
    viewMode = 'default',
  } = props;

  const initialListFilterState: {
    show_open: boolean;
    show_closed: boolean;
    assigned_to_me: boolean;
    committee_id?: number | string;
  } = {
    show_open: true,
    show_closed: false,
    assigned_to_me: false,
    committee_id: committee_id ?? '',
  };

  const {
    loadActionRegisterOptions,
    loadActionRegisterItem,
    saveActionRegisterItem,
    loadActionRegisterItemList,
  } = useSelector(selectActionRegister);

  const [listFilter, setListFilter] = React.useState(initialListFilterState);

  const handleFilterChange = event => {
    const value = event.target.value;
    switch (value) {
      case 'open':
        setListFilter({
          ...listFilter,
          show_open: true,
          show_closed: false,
        });
        break;
      case 'closed':
        setListFilter({
          ...listFilter,
          show_open: false,
          show_closed: true,
        });
        break;
      default:
        setListFilter({
          ...listFilter,
          show_open: true,
          show_closed: true,
        });
    }
  };

  const save = () => {
    serveFile(
      getApiUrl(
        `exports/action-register?organisation_id=${organisation_id}&committee_id=${
          committee_id ?? ''
        }&nonce=${now()}`,
      ),
    )
      .then(noop)
      .catch(noop);
  };

  const getFilterValue = filter => {
    if (filter.show_open && !filter.show_closed) return 'open';
    if (!filter.show_open && filter.show_closed) return 'closed';
    return 'all';
  };

  const [formOpen, setFormOpen] = React.useState(false);

  const { actions } = useActionRegisterSlice();

  const dispatch = useDispatch();

  const load = useCallback(() => {
    dispatch(
      actions.loadActionRegisterItemListRequest({
        organisation_id: organisation_id,
        committee_id: committee_id,
        meeting_id: meeting_id,
        ...listFilter,
      }),
    );
  }, [organisation_id, committee_id, meeting_id, listFilter]);

  useEffect(() => {
    dispatch(
      actions.loadActionRegisterOptionsRequest({ id: Number(organisation_id) }),
    );
  }, [organisation_id]);

  useEffect(() => {
    load();
  }, [load]);

  useEffectOnChange(
    () => {
      dispatch(actions.loadActionRegisterItemInit());
    },
    formOpen,
    false,
  );

  useHandleApiResponse(loadActionRegisterItem, null, {
    onSuccess: () => {
      setFormOpen(true);
    },
  });

  useHandleApiResponse(saveActionRegisterItem, 'Item saved', {
    onSuccess: () => {
      setFormOpen(false);
      load();
    },
    errorMessage: 'Failed to save item',
  });

  const canAddAction = useMemo(() => {
    return (
      !!loadActionRegisterOptions.data?.managed_committees.length &&
      (!committee_id ||
        loadActionRegisterOptions.data?.managed_committees.find(
          c => c.id === committee_id,
        ))
    );
  }, [loadActionRegisterOptions.data, committee_id]);

  const { hasPermission } = useHasPermission();

  const canExport = hasPermission(
    'export-action-register',
    'organisation',
    organisation_id,
  );

  return (
    <Stack spacing={4}>
      <Box>
        <Grid container>
          <Grid item xs={12} md={viewMode === 'default' ? 8 : 12}>
            {viewMode === 'default' ? (
              <>
                You can view all actions and filter them as needed. Only the
                organisation admin can create, edit, and update actions for all
                committees, while a committee admin can manage actions but only
                for their specific committee.
              </>
            ) : (
              <>{introText}</>
            )}
          </Grid>
          {canAddAction && viewMode === 'default' && (
            <Grid item xs={12} md={4}>
              <Stack
                direction={'row'}
                spacing={2}
                justifyContent={{ xs: 'start', md: 'end' }}
              >
                {canExport && (
                  <Button
                    variant="outlined"
                    startIcon={<RiDownload2Line />}
                    disabled={!loadActionRegisterItemList.data.length}
                    onClick={() => save()}
                  >
                    Export
                  </Button>
                )}
                {canAddAction && (
                  <Button
                    startIcon={<RiAddCircleFill />}
                    onClick={() => setFormOpen(true)}
                  >
                    Add action
                  </Button>
                )}
              </Stack>
            </Grid>
          )}
        </Grid>
      </Box>

      <Box>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent={'space-between'}
          alignItems={{ xs: 'start', md: 'center' }}
          spacing={{ xs: 0, md: 1 }}
        >
          <Box>
            <Stack direction={{ xs: 'column', md: 'row' }}>
              <SelectControl
                sx={{ minWidth: 200 }}
                onChange={handleFilterChange}
                value={getFilterValue(listFilter)}
              >
                <MenuItem value="all">All actions</MenuItem>
                <MenuItem value="open">Show in progress</MenuItem>
                <MenuItem value="closed">Show closed</MenuItem>
              </SelectControl>

              {!props.committee_id && (
                <Box>
                  <SelectControl
                    sx={{ minWidth: 200 }}
                    onChange={e =>
                      setListFilter({
                        ...listFilter,
                        committee_id: +e.target.value,
                      })
                    }
                    value={listFilter.committee_id || ''}
                  >
                    <MenuItem value={''}>All committees</MenuItem>
                    {loadActionRegisterOptions.data.viewable_committees.map(
                      committee => (
                        <MenuItem value={committee.id} key={key(committee)}>
                          {committee.name}
                        </MenuItem>
                      ),
                    )}
                  </SelectControl>
                </Box>
              )}

              <FormControlLabel
                control={
                  <Checkbox
                    checked={listFilter.assigned_to_me}
                    onChange={e =>
                      setListFilter({
                        ...listFilter,
                        assigned_to_me: !!e.target.checked,
                      })
                    }
                  />
                }
                label="Only assigned to me"
              />
            </Stack>
          </Box>
          <Box>
            <Button
              variant="text"
              onClick={() => setListFilter(initialListFilterState)}
            >
              Reset filters
            </Button>
          </Box>
        </Stack>
      </Box>

      <Stack>
        <ActionRegisterTable
          context={viewMode}
          actions={loadActionRegisterItemList.data}
          onStatusChange={(id, status) => {
            dispatch(actions.saveActionRegisterItemRequest({ id, status }));
          }}
          onEdit={id => {
            dispatch(actions.loadActionRegisterItemRequest({ id }));
          }}
          onDelete={id => {
            dispatch(actions.deleteActionRegisterItemRequest({ id }));
          }}
          manageableCommitteeIds={loadActionRegisterOptions.data.managed_committees.map(
            committee => committee.id,
          )}
        />
        {viewMode !== 'default' && (
          <Box>
            <Grid container>
              <Grid item xs={12} md={4}>
                <Button
                  variant="outlined"
                  fullWidth
                  component={Link}
                  to={`/organisation/${organisation_id}/actions/all`}
                >
                  Browse and review all actions
                </Button>
              </Grid>
              <Grid item xs={12} md={4}>
                {canExport && (
                  <Button
                    variant="outlined"
                    startIcon={<RiDownload2Line />}
                    fullWidth
                    disabled={!loadActionRegisterItemList.data.length}
                    onClick={() => save()}
                  >
                    Export
                  </Button>
                )}
              </Grid>
              {canAddAction && (
                <Grid item xs={12} md={4}>
                  <Button
                    startIcon={<RiAddCircleFill />}
                    fullWidth
                    onClick={() => setFormOpen(true)}
                  >
                    Add action
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
        )}
        <Dialog
          open={formOpen}
          onClose={() => setFormOpen(false)}
          fullWidth
          maxWidth={'md'}
        >
          <ActionRegisterForm
            action={loadActionRegisterItem.data}
            organisation_id={+organisation_id}
            committee_id={+committee_id}
            meeting_id={meeting_id}
            saving={saveActionRegisterItem.loading}
            onSave={values => {
              dispatch(actions.saveActionRegisterItemRequest(values));
            }}
            committees={loadActionRegisterOptions.data.managed_committees}
            onClose={() => setFormOpen(false)}
          />
        </Dialog>
      </Stack>
    </Stack>
  );
}
