import { MenuItem } from '@mui/material';
import { useHasPermission } from 'app/providers/AuthProvider/useHasPermission';
import { useOrganisationSelector } from 'app/providers/OrganisationSelector/useOrganisationSelector';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  onClick?: () => void;
}

export function OrganisationSelectLinks(props: Props) {
  const { onClick } = props;
  const { hasPermission } = useHasPermission();
  const { currentOrganisationId: id } = useOrganisationSelector();
  const canViewOrgProfile = hasPermission(
    'view-organisation-profile',
    'organisation',
    id,
  );
  const canViewPartnerDetails = hasPermission(
    'view-partner-details',
    'organisation',
    id,
  );

  const canManageAcademy = hasPermission(
    'manage-academy-seats',
    'organisation',
    id,
  );
  return (
    <>
      {canViewOrgProfile && (
        <MenuItem
          component={Link}
          onClick={onClick}
          to={`organisation/${id}/organisation-profile`}
        >
          Organisation Profile
        </MenuItem>
      )}
      {canViewPartnerDetails && (
        <MenuItem
          onClick={onClick}
          component={Link}
          to={`/organisation/${id}/partner-details`}
        >
          Partner Details
        </MenuItem>
      )}
      {canManageAcademy && (
        <MenuItem
          component={Link}
          onClick={onClick}
          to={`/organisation/${id}/academy-seats`}
        >
          Academy Seats
        </MenuItem>
      )}
    </>
  );
}
