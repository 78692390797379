import { Edit, KeyboardArrowDownSharp } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import React from 'react';
import { Link } from 'react-router-dom';
import { IActionRegisterItem } from 'types/types';
import { getInitials } from 'utils/initials';
import { ActionStatusSelect } from '../../../components/ActionStatusSelect';
import { noop } from 'lodash';
import { getDateWIthFallbackString } from '../../../../utils/getDateWIthFallbackString';
import { ActionStatusChip } from 'app/components/ActionStatusChip';

interface Props {
  actions?: IActionRegisterItem[];
  manageableCommitteeIds?: number[];
  context?: 'default' | 'meeting' | 'dashboard';
  onStatusChange?: (id: number, status: 'open' | 'closed') => void;
  saving?: boolean;
  onEdit?: (id: number) => void;
  onDelete?: (id: number) => void;
}

export function ActionRegisterTable(props: Props) {
  const {
    actions,
    manageableCommitteeIds = [],
    context,
    onStatusChange = noop,
    onEdit = noop,
    saving = false,
  } = props;

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Stack>
      <DataGridPro
        loading={saving}
        slots={{
          noRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No actions
            </Stack>
          ),
          noResultsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No actions
            </Stack>
          ),
        }}
        columns={[
          {
            field: 'reference',
            headerName: 'Ref No',
            minWidth: 100,
            flex: 0.1,
          },
          {
            field: 'description',
            minWidth: 150,
            headerName: 'Action',
            flex: 1,
            renderCell: params => {
              return manageableCommitteeIds.find(
                id => id === params.row.committee_id,
              ) ? (
                <Button
                  size="small"
                  onClick={() => onEdit(params.id)}
                  variant="text"
                  endIcon={<Edit />}
                  sx={{
                    pl: 0,
                  }}
                >
                  <Typography variant={'body2'}>{params.value}</Typography>
                </Button>
              ) : (
                <Typography variant={'body2'}>{params.value}</Typography>
              );
            },
          },

          {
            field: 'committee_name',
            headerName: 'Committee',
            minWidth: 150,
            flex: 0.2,
            renderCell: params => {
              const committeePath = () => {
                if (context === 'default' || context === 'meeting') {
                  return `../../committees/${params.row.committee_id}`;
                } else if (context === 'dashboard') {
                  return `committees/${params.row.committee_id}`;
                }
              };
              return (
                <Button
                  size="small"
                  component={Link}
                  to={committeePath()}
                  variant="text"
                  sx={{ pl: 0 }}
                >
                  <Typography variant={'body2'}>
                    {params.row.committee_name}
                  </Typography>
                </Button>
              );
            },
          },
          {
            field: 'assignedTo',
            headerName: 'Assigned to',
            minWidth: 150,
            renderCell: params => {
              const avatarColor = index => {
                const colors = [
                  'secondary.light',
                  'success.light',
                  'primary.light',
                  'warning.light',
                ];
                return colors[index % 4];
              };

              const assignees = params.row.members.map(
                member => `${member.first_name} ${member.last_name}`,
              );

              if (assignees.length === 0) {
                return <Typography variant={'body2'}>No assignees</Typography>;
              }

              const getSurnameInitial = (name: string) => {
                const nameParts = name.split(' ');
                if (nameParts.length >= 2) {
                  return `${nameParts[0]} ${
                    nameParts[nameParts.length - 1][0]
                  }`;
                }
                return name;
              };

              return (
                <>
                  {assignees.length === 1 ? (
                    <Typography variant={'body2'}>
                      {getSurnameInitial(assignees[0])}
                    </Typography>
                  ) : (
                    <Box sx={{ overflowX: 'auto' }}>
                      <Stack direction={'row'} spacing={-1}>
                        {assignees.map((val, index) => (
                          <React.Fragment key={index}>
                            <Tooltip title={val}>
                              <Avatar
                                sx={{
                                  backgroundColor: avatarColor(index),
                                  color: 'black',
                                }}
                              >
                                {getInitials(val)}
                              </Avatar>
                            </Tooltip>
                          </React.Fragment>
                        ))}
                      </Stack>
                    </Box>
                  )}
                </>
              );
            },
          },
          {
            field: 'created_at',
            type: 'date',
            headerName: 'Opened',
            minWidth: 100,
            flex: 0.1,
            renderCell: params => {
              return (
                <Typography variant={'body2'}>
                  {params.value.toLocaleDateString()}
                </Typography>
              );
            },
            valueGetter: ({ row }) => {
              const dateValue = getDateWIthFallbackString(row.created_at);
              return new Date(dateValue);
            },
          },

          {
            field: 'due_at',
            type: 'date',
            headerName: 'Due',
            minWidth: 100,
            flex: 0.1,
            renderCell: params => {
              return (
                <Typography variant={'body2'}>
                  {params.value.toLocaleDateString()}
                </Typography>
              );
            },
            valueGetter: ({ row }) => {
              const dateValue = getDateWIthFallbackString(row.due_at);
              return new Date(dateValue);
            },
          },

          {
            field: 'status',
            headerName: 'Status',
            minWidth: 120,
            flex: 0.1,
            renderCell: params => {
              const status = params.value;
              const editable = manageableCommitteeIds.find(
                id => id === params.row.committee_id,
              );

              const statuses = [
                { value: 'open', label: 'Open' },
                { value: 'closed', label: 'Closed' },
              ];

              const currentStatus = statuses.find(s => s.value === status);
              const statusLabel = currentStatus ? currentStatus.label : status;

              return (
                <>
                  {editable ? (
                    <ActionStatusSelect label={statusLabel} status={status}>
                      {statuses.map(({ value, label }) => (
                        <MenuItem
                          onClick={() => {
                            if (params.value !== value) {
                              onStatusChange(params.id, value);
                            }
                          }}
                          key={value}
                          value={value}
                        >
                          <Typography variant="body2">{label}</Typography>
                        </MenuItem>
                      ))}
                    </ActionStatusSelect>
                  ) : (
                    <ActionStatusChip status={status} title={statusLabel} />
                  )}
                </>
              );
            },
          },
        ]}
        columnVisibilityModel={{
          committee_name: context !== 'meeting',
          created_at: isXs,
        }}
        rows={[...actions]}
        autoHeight
        disableColumnMenu
        hideFooter
        sx={{
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: 'primary.lightest',
          },
        }}
      />
    </Stack>
  );
}
